<template>
  <div>
    <Header />
    <Banner />
    <div class="main-page">
      <el-card shadow="never" class="selectMainDiv">
        <p class="selectMainTitle">请选择认证类型 <span>应相关法规要求，发布任务需先通过实名认证</span></p>
        <div class="selectMainBtn" v-if="false">
          <div @click="changeSelectAudit( 2 )" :class="{ 'selectMainBtnItem': true, 'checked': audit_type==2  }">
            <p class="selectMainBtnItemMemo">
              <i :class="{ 'iconfont': true, 'icon-hirer': true, 'wh-32': true, 'h-b5': audit_type!=2, 'b': audit_type==2 }"></i>
              <span>企业认证</span>
              <span class="memo-text">需要完善企业认证信息</span>
            </p>
          </div>
          <div @click="changeSelectAudit( 1 )" :class="{ 'selectMainBtnItem-two': true, 'checked': audit_type==1  }">
            <p class="selectMainBtnItemMemo">
              <i :class="{ 'iconfont': true, 'icon-personal': true, 'wh-32': true, 'h-b5': audit_type!=1, 'b': audit_type==1 }"></i>
              <span>实名认证</span>
              <span class="memo-text">需要完善实名认证信息</span>
            </p>
          </div>
        </div>

        <div class="selectMainMemo">
          <!--个人认证-->
          <p v-if="audit_type==1"><i class="iconfont icon-tips r wh-12">温馨提示：</i>实名认证是参与平台经营和资金提现的必要条件，请立即完善。目前支持中国大陆地区第二代身份证。持有台湾省、香港特别行政区、澳门特别行政区身份证件的用户请致电：010-64575166。我们将加密存储您的信息，请安心使用。
          </p>
          <!--企业认证-->
          <p v-if="audit_type==2"><i class="iconfont icon-tips r wh-12">温馨提示：</i>请确保您是具有法人资格的商户，您需提供可证明您依法设立、依法经营、开展社会活动的执照、证件等（如营业执照副证件有效期在3个月内的商户，请找工商部门更新资料后再行提交。
          </p>
        </div>

        <CompanyUserAuth
            ref="companyBox"
            v-if="audit_type==2"
            :task_id="task_id"
            :audit_id="audit_id"
            :is-only-add="false"
            :is-show-form-and-info="false"
            @auditBackData="auditCompanyBackData">
        </CompanyUserAuth>

        <RealNameUserAuth
            ref="realnameBox"
            v-if="audit_type==1"
            :task_id="task_id"
            :is-only-add="false"
            :is-show-form-and-info="false"
            @auditBackData="auditRealNameBackData">
        </RealNameUserAuth>

        <div class="selectMainSubBtn">
          <el-button type="primary" size="mini" @click="toNext"> 确定</el-button>
<!--          <el-button v-if="current_audit_id>0"  size="mini" @click="toCancel"> 取消 </el-button>-->
          <el-button size="mini" @click="toSkip"> <span v-if="audit_id==0" >跳过</span> <span v-else> 返回 </span> </el-button>
        </div>
      </el-card>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Banner from '@/components/Banner';
import CompanyUserAuth from "@/components/UserAuth/company";
import RealNameUserAuth from "@/components/UserAuth/realname";

export default {
  name: "taskselectauth",
  components: {
    Banner,
    CompanyUserAuth,
    RealNameUserAuth
  },
  data() {
    return {
      auditTypes: {
        real_name: 1,
        company: 2
      },
      audit_type: 2,
      task_id: 0,
      audit_id: 0,
      current_audit_id: 0,
      current_audit_data: {}
    }
  },
  created() {
    if( this.$route.query.id != undefined ){
      this.audit_id = this.$route.query.id
    }
    if( this.$route.query.type != undefined ){
      this.audit_type = this.$route.query.type
      if( this.audit_type == 0 ){
        this.audit_type = 2// 没有的话默认 企业
      }
    }
  },
  mounted() {
    this.task_id = this.$route.params.id
  },
  methods: {
    auditCompanyBackData(data) {
      this.current_audit_id = data.id
      this.current_audit_data = data
    },
    auditRealNameBackData(data) {
      this.current_audit_id = data.id
      this.current_audit_data = data
    },
    changeSelectAudit(type) {
      this.audit_type = type
      this.current_audit_id = 0
    },
    toNext() {
      switch ( parseInt( this.audit_type ) ){
        case this.auditTypes.real_name:
          if( this.current_audit_id > 0 ){// 选中的直接提交保存
            this.$refs.realnameBox.selectTaskAuth({'sn': this.task_id, 'auth_id': this.current_audit_id})
          }else{
            this.$refs.realnameBox.toSubmit()// 新建的走提交保存流程
          }
          break;
        case this.auditTypes.company:
          if( this.current_audit_id > 0 ){// 选中的直接提交保存
            this.$refs.companyBox.selectTaskAuth({'sn': this.task_id, 'auth_id': this.current_audit_id})
          }else{
            this.$refs.companyBox.toSubmit()
          }
          break;
      }
    },
    toSkip() {
      this.$redirectUrl.jumpToFrom('/task/result/' + this.task_id);
    },
    toCancel(){
      switch ( parseInt( this.audit_type ) ){
        case this.auditTypes.real_name:
          this.$refs.realnameBox.toCancel()
          break;
        case this.auditTypes.company:
          this.$refs.companyBox.toCancel()
          break;
      }
    }
  }
}
</script>

<style lang="less" scoped>
header {
  margin-bottom: unset;
}

.main-page {
  margin-top: -250px;
}

.selectMainDiv {
  width: 800px;
  min-height: 600px;
  margin: 0 auto;
}

.selectMainTitle {
  font-size: 16px;
  font-weight: bold;

  span {
    font-size: 12px;
  }
}

.selectMainBtn {
  width: 100%;
  height: 80px;
  margin-top: 20px;
}

.selectMainBtnItem {
  cursor: pointer;
  width: 350px;
  height: 70px;
  margin-right: 30px;
  float: left;
  border: 1px solid #b5b5b5;
}

.selectMainBtnItem.checked {
  border: 1px solid #00a2e6 !important;
}

.selectMainBtnItem-two {
  cursor: pointer;
  width: 350px;
  height: 70px;
  float: left;
  border: 1px solid #b5b5b5;

}

.selectMainBtnItem-two.checked {
  border: 1px solid #00a2e6 !important;
}

.selectMainBtnItemMemo {
  text-align: center;
  line-height: 40px;
  margin-top: 15px;
  height: 40px;

  i {
    vertical-align: middle;
  }

  span {
    font-size: 16px;
    color: #575757;
    margin-left: 20px;
    margin-bottom: 10px;
    vertical-align: middle;
  }

  .memo-text {
    margin-left: 40px;
    font-size: 12px;
    margin-bottom: 10px;
    vertical-align: middle;
  }
}

.selectMainMemo {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 22px;
}

.selectMainSubBtn {
  width: 100%;
  text-align: center;

  /deep/ .el-button {
    width: 160px;
  }
}

/deep/ .el-card__body {
  padding: 20px 30px;
}

/deep/ .el-button--primary {
  background-color: #00a2e6;
}

</style>
